<template>
  <menu-right
    :title="direction"
    :class="classes"
    :size="size"
  />
</template>
<script>
import MenuRight from 'vue-material-design-icons/MenuRight.vue'

export default {
  name: 'ic-arrow',
  components: { MenuRight },
  props: {
    direction: {
      type: String,
      default: 'up',
      validator: val => ['up', 'down'].includes(val),
    },
    size: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    classes() {
      return {
        down: this.direction === 'down',
        up: this.direction === 'up',
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.down {
  transform: rotate(90deg);
}
.up {
  transform: rotate(-90deg);
}
</style>
